import React from 'react'
import {Link, withPrefix, graphql} from 'gatsby'
import ParticlesBackground from '../components/layouts/particlesbackground'
import HeadMeta from '../components/layouts/headmeta'
import ProgressBar from '../components/layouts/progressbar'
import Layout from '../components/layouts/layout'
import PanelsWithViewMore from '../components/panelswithviewmore'
import Faq from '../components/faq'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class ReportsPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Reports', this.props.data.markdownRemark.frontmatter.reports_category)
  }

  render() {
    const { frontmatter } = this.props.data.markdownRemark // data.markdownRemark holds your post data
    const {
      metadata,
      reports,
      description,
      traits,
      what_you_get,
      cta,
      faq
    } = frontmatter
    let sampleReport;
    if (reports.sample_img) {
      sampleReport =
        <section className="pt-0">
          <div className="row align-items-center">
            <div className="col-md-4 offset-md-1">
              <h2>What You'll Get</h2>
              <div dangerouslySetInnerHTML={{__html: what_you_get}}></div>
              <a href={reports.sample_report_link} className="btn btn-warning" target="_blank" rel="noopener noreferrer">View Sample Report</a>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <img src={withPrefix(reports.sample_img)} style={{maxHeight: '80vh'}} alt="sample report"/>
            </div>
          </div>
        </section>
    }
    return (
      <Layout>
        <div className="reports-page">
          <ProgressBar />
          <HeadMeta metadata={metadata} />
          <section className="hero bg-gradient-blue-green text-white" style={{height: '50vh'}}>
            <ParticlesBackground />
            <div className="container text-center">
              <h1 className="text-center">{reports.category} Reports</h1>
              <div className="col-md-8 offset-md-2">
                <p className="text-black" dangerouslySetInnerHTML={{__html: description}}></p>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 text-center">
                <h2>What You'll Discover</h2>
                <p>{traits.description}</p>
              </div>
            </div>
            <PanelsWithViewMore traits={traits.data} />
          </section>
          <section className="container pt-0">
            <h2 className="text-center">What You Would Get In These Reports</h2>
            <div className="row my-5 align-items-center">
              {
                reports.section.map((s) => {
                  return (
                    <div className="item-holder col-sm-4">
                      <div className="card">
                        <img className="card-img-top" src={withPrefix(s.img)} alt="reports" />
                        <div className="card-body text-center">
                          <h5 className="card-title">{s.name}</h5>
                          <p className="card-text">{s.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>
          {sampleReport}
          <section id="cta" className="cta bg-gradient">
            <div className="container">
              <h2 className="mb-5 text-center" dangerouslySetInnerHTML={{__html: cta.title}}></h2>
              <div className="row align-items-center">
              {
                cta.products.map((product) => {
                  return (
                    <div className="col-md-6">
                    <div className="form-holder">
                      <img src={withPrefix(product.name)} className="img-fluid" style={{maxWidth: '250px'}} alt={product.name}/>
                      <hr/>
                      <p>{product.description}</p>
                      <Link className={`btn ${product.buttonColor}`} to={withPrefix(product.link)}>Learn more </Link>
                    </div>
                    </div>
                  )
                })
              }
              </div>
            </div>
          </section>
          <section id="faq">
            <Faq faqs={faq} />
          </section>
        </div>
      </Layout>
    )
  }
}
export const reportsPageQuery = graphql`
  query ReportsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        metadata {
          title
          description
          url
          image
        }
        reports {
          category
          number
          price
          sample_img
          sample_report_link
          section {
            name
            description
            img
          }
        }
        description
        what_you_get
        traits {
          description
          data {
            name
            tag
            description
          }
        }
        cta {
          title
          products {
            name
            description
            link
            buttonColor
          }
        }
        faq {
          question
          answer
        }
      }
    }
  }
`
