import React from 'react'

export default class PanelsWithViewMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  handleShowMore = (e) => {
    e.preventDefault();
    this.setState({showMore: !this.state.showMore});
  }

  render() {
    const { traits } = this.props
    let panelClass = 'show-more-panels'
    if (this.state.showMore) {
      panelClass = 'unmasked-panels'
    }
    return (
      <div className="panel-parent ">
        <div className={`row m-2 m-md-4 ${panelClass}`}>
          {
            traits.map((trait) => (
              <div className="col-md-6 my-3 px-md-5">
                <p className="show-more-panels__title">{trait.name}<span className="badge badge-warning ml-2">{trait.tag}</span></p>
                <p>{trait.description}</p>
              </div>
            ))
          }
          <a href="#" class="show-more-panels__view-more" onClick={this.handleShowMore}>View more</a>
        </div>
      </div>
    )
  }
}
